<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.attendance') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="attendance" :class="'btn btn-success text-light'">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <!-- <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row> -->
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="formData.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select> -->
                                  <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Batch No" vid="batch_no" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                            <template v-slot:label>
                              {{$t('elearning_iabm.batch_no')}}
                            </template>
                              <b-form-select
                                plain
                                v-model="formData.batch_no"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                     <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                      </b-row>
                      <b-row>
                        <div class="col-md-12">
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend>
                              <b-overlay :show="loading">
                                <b-table-simple hover bordered responsive >
                                  <b-thead class="thead">
                                    <b-tr>
                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.sl_no')}}</b-th>
                                      <b-th rowspan="3" style="width: 15% !important;vertical-align:middle;">{{$t('elearning_tim.trainee')}}</b-th>
                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('elearning_tim.posting_office')}}</b-th>
                                      <b-th rowspan="3" style="vertical-align:middle">{{$t('globalTrans.mobile')}}</b-th>
                                      <b-th style="width:10%;vertical-align:middle" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <slot v-for="(field) in dateList">
                                        <b-th class="text-center" colspan="2">
                                            {{ field.text | dateFormat }}
                                            <b-form-checkbox
                                              v-model="field.approved"
                                              unchecked-value=0
                                              value=1
                                              :disabled="checkDate(field)"
                                              >
                                              {{$t('globalTrans.approved')}}
                                            </b-form-checkbox>
                                        </b-th>
                                      </slot>
                                    </b-tr>
                                    <b-tr>
                                      <slot v-for="(field, index) in dateList">
                                        <b-th class="text-center" :id="'morning-' + field.value">
                                          <b-form-checkbox
                                            unchecked-value="0"
                                            :id="'morning-' + index"
                                            v-model="field.morning"
                                            value=2
                                            @change="checkData(field, 'morning')"
                                            :disabled="checkDate(field)"
                                            >
                                            {{$t('globalTrans.morning')}}
                                          </b-form-checkbox>
                                        </b-th>
                                        <b-th class="text-center" :id="'day-' + field.value">
                                          <b-form-checkbox
                                            unchecked-value="0"
                                            :id="'day-' + index"
                                            v-model="field.day"
                                            value=2
                                            @change="checkData(field, 'day')"
                                            :disabled="checkDate(field)"
                                            >
                                            {{$t('globalTrans.evening')}}
                                          </b-form-checkbox>
                                        </b-th>
                                      </slot>
                                    </b-tr>
                                  </b-thead>
                                  <b-tr v-for="(train,index) in details" :key="index">
                                    <b-td class="text-center">{{$n(index+1)}}</b-td>
                                    <b-td>
                                      {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                    </b-td>
                                    <b-td class="text-center">
                                      <span v-if="train.designation_id && train.not_here_designation == 0 && train.profession_type == 1">{{ getDesignation(train.designation_id) }}</span>
                                      <span v-else>{{ ($i18n.locale=='bn') ? train.designation_bn : train.designation_en}}</span>
                                    </b-td>
                                    <b-td class="text-center">
                                      <span v-if="train.profession_type == 1 && train.not_here_office == 0">{{ getOfficeName(train.professional_office_id) + ', ' + getOrgName(train.professional_org_id) }}</span>
                                      <span v-if="train.profession_type == 1 && train.not_here_office == 1">{{ ($i18n.locale=='bn') ? train.office_name_bn : train.office_name + ', ' + getOrgName(train.professional_org_id) }}</span>
                                      <span v-if="train.profession_type != 1">{{ ($i18n.locale=='bn') ? train.office_name_bn : train.office_name }}</span>
                                    </b-td>
                                    <b-td>
                                      {{ train.mobile }}
                                      <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                                        {{ detailsErrors['details.'+index+'.mobile'] }}
                                      </small>
                                    </b-td>
                                    <slot v-for="(field, index1) in dateList">
                                      <b-th class="text-center">
                                        <input
                                          type="checkbox"
                                          v-model="details[index].dateList[index1].morning"
                                          :disabled="checkDate(field)"
                                        >
                                      </b-th>
                                      <b-th class="text-center">
                                        <input
                                          type="checkbox"
                                          v-model="details[index].dateList[index1].day"
                                          :disabled="checkDate(field)"
                                        >
                                      </b-th>
                                    </slot>
                                  </b-tr>
                                  <template v-if="details.length === 0">
                                    <tr>
                                      <th :colspan="dateList.length * 2 + 6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                    </tr>
                                  </template>
                                </b-table-simple>
                              </b-overlay>
                          </fieldset>
                        </div>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <router-link to="attendance" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                        </div>
                      </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, attendanceAllList } from '../../api/routes'
import flatpickr from 'flatpickr'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        coordinator_id: 0,
        circular_memo_no: 0,
        fiscal_year_id: null,
        office_type_id: 0,
        office_id: 0,
        org_id: null,
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        batch_no: 0,
        training_date: '',
        trainer_id: 0,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: [],
        user_status: 0
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      officeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      allBatchListData: [],
      trainingTypeList: [],
      batchList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      approvedDate: '',
      dateList: [],
      myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.getCircularList()
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
        this.getAttedenceList()
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
        }
        this.getAttedenceList()
      }
    }
  },
  methods: {
    getDesignation (id) {
      const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return designation !== undefined ? designation.text_bn : ''
      } else {
        return designation !== undefined ? designation.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.formData.fiscal_year_id,
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id,
        coordinator_id: this.formData.coordinator_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/circular-publication-list' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.circularList = []
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async getAttedenceList () {
      if (this.formData.circular_memo_no && this.formData.batch_no) {
        this.loading = true
        const params = {
          circular_memo_no: this.formData.circular_memo_no,
          batch_no: this.formData.batch_no
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, attendanceAllList, params)
        if (result.success) {
          this.circularWiseAttendanceList = result.circularWiseAttendance
          const listData = result.aryRange.map(item => {
            const updateData = {}
            updateData.checked = false
            updateData.morning = false
            updateData.day = false
            updateData.text = item
            updateData.approved = 0
            return Object.assign({}, updateData)
          })
          this.dateList = listData
          if (result.attendenceDate) {
            this.dateList = result.attendenceDate
          }
          const datas = result.data.map(item => {
            var dataEvent = []
            const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_data_name = officeObj.text_en
              officeData.office_data_name_bn = officeObj.text_bn
            } else {
              officeData.office_data_name = ''
              officeData.office_data_name_bn = ''
            }
            this.dateList.forEach((dateList) => {
              const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
              const items = {}
              items.training_application_id = item.id
              items.attendence_status = false
              if (typeof saveObj !== 'undefined') {
                if (saveObj.status === 1) {
                  items.attendence_status = true
                }
                items.morning = saveObj.morning
                items.day = saveObj.day
                items.text = dateList.text
              } else {
                items.morning = false
                items.day = false
                items.text = dateList.text
              }
              dataEvent.push(items)
            })
            const newData = {
                dateList: dataEvent
            }
            return Object.assign({}, item, desigData, newData, orgData, officeData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = {}
          this.loading = false
        }
      }
    },
    checkData (item, type) {
      this.details.forEach((detail, detailIndex) => {
        detail.dateList.forEach((dateList, dateListIndex) => {
          if (dateList.text === item.text) {
            if (type === 'morning') {
              if (item.morning === '2') {
                dateList.morning = true
              } else {
              dateList.morning = false
              }
            } else {
              if (item.day === '2') {
                dateList.day = true
              } else {
              dateList.day = false
              }
            }
          }
        })
      })
    },
    checkDate (item) {
      if (item.text > today) {
        return true
      } else {
        return false
      }
    },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      this.formData.dateList = this.dateList
      this.formData.user_status = this.$store.state.Auth.authUser.is_org_admin
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        // result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.getAttedenceList()
        this.$router.push('/training-e-learning-service/tim/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
